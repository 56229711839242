<template>
	<div>
		<div class="form-group">
            <label>{{ $t('horse.cat_cheval')}}</label>
	        <select v-if="!readonly"
	            class="custom-select mb-3"
	            :placeholder="$t('horse.categorie_cheval')"
	            v-model="selected_categorie"
	            @change="addCategorie"
            >

                <option value="0">{{ $t('horse.ajouter_categorie') }}</option>

                <option
                    v-for="cat in categorie_to_show"
                    :key="cat.categorie_libelle"
                    :value="cat"
                    >{{ cat.categorie_libelle }}
                </option>
            </select>

			<div clas="row">
	        	<button 
					v-for="(cat, index) in local_selected_horse_categories" :key="'choice_'+index"
					:disabled="readonly"
					@click="deleteCategorie(cat)" class="btn btn-sm btn-primary rounded-pill mr-2">
					<font-awesome-icon v-if="cat.categorie_public" :icon="['far', 'globe']" style="color: #2ecc19;"/>
					{{ cat.categorie_libelle }} 
					<font-awesome-icon :icon="['fal', 'times']" />
				</button>
			</div>

			<div v-if="selectPlaceholder" clas="row">
				<span v-if="selectPlaceholder">{{ $t('horse.aucune_categorie') }}</span>
			</div>
        </div>
    </div>
</template>

<script type="text/javascript">
    import HorseMixin from "@/mixins/Horse.js";

    export default {
        mixins: [HorseMixin],
        props: [
            'horse_id',
			'readonly'
        ],
        data (){
            return {
            	horse_categories: [],
            	selected_categorie: 0,
				selected_horse_categories: []
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
				this.updateHorseCategories()
				this.horse_categories = await this.getAllCategorie()
			},

			async updateHorseCategories() {
				this.selected_horse_categories = await this.getHorseCategories(this.horse_id)
			},

			addCategorie() {
				if(this.selected_categorie.categorie_id != 0){
					this.addHorseCategorie(this.horse_id, this.selected_categorie.categorie_id).finally((res) => {
						this.selected_categorie = 0
						this.updateHorseCategories()
					})
				}
			},
			deleteCategorie(cat) {
				this.removeHorseCategorie(this.horse_id, cat.categorie_id).finally(() => {
					this.updateHorseCategories()
				})
			}
        },
        computed: {
        	categorie_to_show() {
        		return this.diffArrayNonExist(this.horse_categories, this.local_selected_horse_categories)
			},
			selectPlaceholder() {
				return this.readonly && this.local_selected_horse_categories.length === 0
			},
			local_selected_horse_categories() {
				return this.horse_categories.filter(cat1 => {
					const exists = this.selected_horse_categories.find(cat2 => cat2.categorie_id == cat1.categorie_id)
					return !!exists
				})
			}
        },
		watch: {
			horse_id(_) {
				this.init_component()
			}
		}
    }
</script>
